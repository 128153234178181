import styled from 'styled-components';

const SublineStyles = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 0.5fr;
  padding: 2em;
  z-index: 2;
  p {
    text-align: justify;
  }
  a {
    color: var(--white);
  }
  a:hover {
    color: var(--grey);
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export default SublineStyles;
