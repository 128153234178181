import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import SublineStyles from '../styles/SublineStyles';

export default function Info() {
  return (
    <Layout>
      <SEO title="Info" />
      <SublineStyles>
        <span />
        <p>
          Hi, I'm Gil, Chicago-based creative director and designer. I help
          agency teams, small businesses, and creative entrepreneurs design and
          build smart and meaningful digital experiences.
          <br />
          <br />I currently freelance at{' '}
          <a
            href="https://www.weareenvoy.com/"
            target="_blank"
            rel="noreferrer"
          >
            Envoy
          </a>
          , where I help with UX and UI for T-Mobile. <br />
          <br />
          <a className="say-hello" href="mailTo:gilfelixperez@gmail.com">
            Say Hello.
          </a>
        </p>
      </SublineStyles>
    </Layout>
  );
}
